<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>车辆信息列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align:left;">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="车牌号"
              v-model="page.car_number"
              clearable
              @clear="onSubmit(page.car_number)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.nature"
              clearable
              placeholder="车辆性质"
              @change="onSubmit(page.user_state)"
            >
              <el-option
                v-for="(item, index) in natureOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="onSubmit"
              >查询</el-button
            >
            <el-button type="success" size="medium" @click="Add"
              >新增车辆</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="车牌号"
                v-model="page.car_number"
                clearable
                class="input-with-select"
                @clear="onSubmit(page.car_number)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select
                v-model="page.nature"
                clearable
                placeholder="车辆性质"
                @change="onSubmit(page.user_state)"
              >
                <el-option
                  v-for="(item, index) in natureOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="onSubmit"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
              <el-button type="primary" size="medium" @click="Add"
                >新增车辆</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
      </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="car_number"
            min-width="120"
            :show-overflow-tooltip="true"
            label="车牌号"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="nature"
            label="车辆性质"
            min-width="100"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column align="center" label="行驶证图片" min-width="100">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.car_driving_url"
                size="small"
                type="primary"
                @click="handleViewPic(scope.row.car_driving_url)"
              >
                查看图片
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="company_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="公司名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="credit_code"
            min-width="120"
            :show-overflow-tooltip="true"
            label="社会统一信用代码"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="add_time"
            min-width="120"
            :show-overflow-tooltip="true"
            label="添加时间"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            label="状态"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-switch
                v-if="scope.row.nature === '自有'"
                v-model="scope.row.car_state"
                style="display: block"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="启用"
                inactive-text="禁用"
                :active-value="1"
                :inactive-value="2"
                @change="handleStateChange($event, scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="goUpdate(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>

      <el-image-viewer
        style="z-index: 9999"
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="url"
      />
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import { local, session } from "@/util/util";
import { mapState } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      user: "",
      page: {
        pager_offset: "0",
        pager_openset: "10",
        car_number: "",
        nature: "",
      },
      timer: "",
      loading: false,
      natureOptions: [
        { label: "全部", value: "" },
        { label: "自有", value: "自有" },
        { label: "租赁", value: "租赁" },
      ],
      currentPage: 1,
      total: 0,
      tableData: [],
      activeName: "1",
      company_id: "",
      url: "",
      showViewer: false,
    };
  },
  filters: {},
  computed: {
    ...mapState({
      carState: (state) => state.carManage.carState,
    }),
  },
  mounted() {
    this.page = Object.assign({}, this.carState);
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    init(params) {
      axios.get("/pc/company-car/list", params).then((v) => {
        this.tableData = v.data.company_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("carManage/setNewPage", this.page);
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("carManage/setNewPage", this.page);
      this.init(this.page);
    },
    //新增处罚方式
    Add() {
      session.set("params", { id: "", name: "" });
      this.$router.push({ name: "CarListAdd" });
    },
    //编辑
    goUpdate(row) {
      session.set("params", { id: row.id, name: row.nature });
      this.$router.push({
        name: "CarListAdd",
      });
    },
    //更改状态
    changeState(row) {
      let { user_id, user_state } = row;
      user_state = user_state == 1 ? 2 : 1;
      axios
        .put("/pc/user/state/update", { user_id, user_state })
        .then((response) => {
          this.init(this.page);
        });
    },
    handleStateChange(val, row) {
      const { id } = row;
      let car_state = val + "";
      axios
        .put("/pc/company-car/state/update", { car_id: id + "", car_state })
        .then((response) => {
          this.init(this.page);
        });
    },
    handleViewPic(src) {
      if (src) {
        // let path = src.slice(1, src.length - 1).split(", ");
        let path = JSON.parse(src);
        this.url = path;
        this.showViewer = true;
      }
    },
    closeViewer() {
      this.showViewer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
